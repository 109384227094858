import { BorderBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import stackblitz from '@stackblitz/sdk';
import { useStore } from 'contexts';
import {
	StackBlitzDependencyDecorator,
	StackBlitzFilesDecorator,
	StackBlitzOpenDecorator,
	StackBlitzProjectDecorator,
} from 'decorators';
import { useAction } from 'hooks';
import { Skeleton } from 'molecules/skeletons';
import { log } from 'utils';

const StackblitzBtn = ({ model, rootApi, loading }) => {
	const store = useStore();

	// Actions
	const [onBlitz, blitzTask] = useAction({}, async () => {
		const response = await store.query({ url: `${rootApi}/tree` });
		const dependencies = StackBlitzDependencyDecorator(response.data);
		const files = StackBlitzFilesDecorator(response.data);
		const project = { ...StackBlitzProjectDecorator(model), template: 'node', dependencies, files };
		const projectSettings = StackBlitzOpenDecorator();
		log('stackblitz: ', { project, projectSettings });
		stackblitz.openProject(project, projectSettings);
	});

	// Render
	return (
		<BorderBtn
			size='lg'
			nextIcon='arrow-up-right'
			disabled={loading}
			taskRunning={blitzTask.running}
			onClick={onBlitz}
			className='w-full'>
			{loading ? <Skeleton className='w-20' /> : <Span>View Stackblitz</Span>}
		</BorderBtn>
	);
};

export { StackblitzBtn };

// Docs
// https://developer.stackblitz.com/platform/api/javascript-sdk#openproject
// https://developer.stackblitz.com/platform/api/javascript-sdk-options#project
// https://developer.stackblitz.com/platform/api/javascript-sdk-options#projectfiles
