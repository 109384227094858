import binaryExtensions from 'binary-extensions';
import { decode } from 'js-base64';
import { isArray, isEmpty } from 'utils';

export const CodeSandboxBodyDecorator = data => ({
	environment: 'server',
	files: data,
});

export const CodeSandboxConfiguration = data => ({
	view: 'terminal',
	startScript: 'npm start',
});

export const CodeSandboxFilesDecorator = (data = []) => {
	const formattedFiles = {};
	const recursiveGenerator = files => {
		files
			.filter(file => !file.name?.slice(0, 1).includes('.'))
			.filter(file => !file.name?.includes('package-lock'))
			.map(file => {
				if (isEmpty(file.content)) return null;
				if (isArray(file.content)) return Object.assign(formattedFiles, recursiveGenerator(file.content));
				if (file.name === 'package.json') return Object.assign(formattedFiles, CodeSandboxPackageDecorator(file));
				const index = file.path.indexOf('.');
				const extension = file.path?.slice(index + 1, file.path.length);
				const isBinary = binaryExtensions.includes(extension);
				if (isBinary) return;
				return (formattedFiles[file.path] = { content: decode(file.content), isBinary });
			});
	};
	recursiveGenerator(data);
	return formattedFiles;
};

export const CodeSandboxPackageDecorator = (file = {}) => {
	const formattedFile = { content: {} };
	const decodedFile = decode(file.content);
	const packageJSON = JSON.parse(decodedFile || '');
	Object.keys(packageJSON).map(key => {
		const data = packageJSON[key];
		if (isArray(data)) return Object.keys(data).map(v => (formattedFile.content[key][v] = data[v]));
		return (formattedFile.content[key] = data);
	});
	return { [file.name]: formattedFile };
};
