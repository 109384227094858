export const decorateSkill = (value, skillableType) => {
	return { skillableId: value.id, skillableType, ranking: value.ranking };
};

export const decoratePartialSkill = ({ skillableId, skillableType }) => {
	return { skillableId, skillableType };
};

export const decorateExistingSkill = ({ skillableId, skillableType, ranking }) => {
	return { skillableId, skillableType, ranking };
};
