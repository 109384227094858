import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { BorderLink } from '@playbooks/interface/links';
import { AuthLink } from 'components/auth/auth-link';
import { Skeleton } from 'molecules/skeletons';

const DemoBtn = ({ type = 'checkout', model, loading }) => {
	const props = { model, loading };

	// Render
	switch (type) {
		case 'hero':
			return <DemoHeroBtn {...props} />;
		case 'checkout':
			return <DemoCheckoutBtn {...props} />;
	}
};

const DemoHeroBtn = ({ model, loading }) => (
	<AuthLink href={model.demo?.computedUrl} disabled={loading} target='_blank'>
		{loading ? (
			<Skeleton theme={{ color: '#e5e7eb', highlight: '#d1d5db' }} className='w-20' />
		) : (
			<Span display='flex-middle' space='space-x-4'>
				<FadIcon icon='arrow-up-right' />
				<Span>View Demo</Span>
			</Span>
		)}
	</AuthLink>
);

const DemoCheckoutBtn = ({ model, loading }) => (
	<BorderLink
		size='lg'
		nextIcon='arrow-up-right'
		href={model.demo?.computedUrl}
		disabled={loading}
		target='_blank'
		className='w-full'>
		{loading ? <Skeleton className='w-20' /> : <Span>View Demo</Span>}
	</BorderLink>
);

export { DemoBtn };
