import binaryExtensions from 'binary-extensions';
import { decode } from 'js-base64';
import { isArray, isEmpty } from 'utils';

export const StackBlitzProjectDecorator = data => ({
	title: data.name,
	description: data.description,
	settings: {
		compile: {
			trigger: 'auto',
			clearConsole: false,
		},
	},
});

export const StackBlitzDependencyDecorator = (data = []) => {
	const formattedDependencies = {};
	const file = data.find(v => v.name === 'package.json');
	if (!file) return formattedDependencies;
	const decodedFile = decode(file.content);
	const packageJSON = JSON.parse(decodedFile || '');
	Object.keys(packageJSON).map(key => {
		const data = packageJSON[key];
		Object.keys(data).map(v => (formattedDependencies[v] = data[v]));
		return (formattedDependencies[key] = data);
	});
	return formattedDependencies;
};

export const StackBlitzFilesDecorator = (data = []) => {
	const formattedFiles = {};
	const recursiveGenerator = files => {
		files
			.filter(file => !file.name?.slice(0, 1).includes('.'))
			.filter(file => !file.name?.includes('package-lock'))
			.map(file => {
				if (isEmpty(file.content)) return null;
				if (isArray(file.content)) return Object.assign(formattedFiles, recursiveGenerator(file.content));
				const index = file.path.indexOf('.');
				const extension = file.path?.slice(index + 1, file.path.length);
				const isBinary = binaryExtensions.includes(extension);
				if (isBinary) return;
				return (formattedFiles[file.path] = decode(file.content));
			});
	};
	recursiveGenerator(data);
	return formattedFiles;
};

export const StackBlitzOpenDecorator = () => ({
	newWindow: true,
	openFile: ['src/index.html'],
});
