import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { AuthBtn } from 'components/auth/auth-btn';
import { Skeleton } from 'molecules/skeletons';

const CheckoutBtn = ({ type = 'checkout', loading, onClick }) => {
	const props = { loading, onClick };

	// Render
	switch (type) {
		case 'hero':
			return <CheckoutHeroBtn {...props} />;
		case 'checkout':
			return <CheckoutCheckoutBtn {...props} />;
	}
};

const CheckoutHeroBtn = ({ loading, onClick }) => (
	<AuthBtn onClick={onClick} disabled={loading}>
		{loading ? (
			<Skeleton theme={{ color: '#e5e7eb', highlight: '#d1d5db' }} className='w-20' />
		) : (
			<Span display='flex-middle' space='space-x-4'>
				<FadIcon icon='credit-card' />
				<Span>Checkout</Span>
			</Span>
		)}
	</AuthBtn>
);

const CheckoutCheckoutBtn = ({ loading, onClick }) => (
	<AuthBtn size='lg' onClick={onClick} disabled={loading} className='w-full'>
		{loading ? (
			<Skeleton theme={{ color: '#e5e7eb', highlight: '#d1d5db' }} className='w-20' />
		) : (
			<Span display='flex-middle' space='space-x-4'>
				<FadIcon icon='credit-card' fontSize='text-xl' />
				<Span>Buy Now</Span>
			</Span>
		)}
	</AuthBtn>
);

export { CheckoutBtn };
