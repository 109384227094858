export const decorateInterest = (relation, followableType) => {
	return {
		followableId: relation.id,
		followableType,
		framework: followableType === 'Framework' ? relation : null,
		language: followableType === 'Language' ? relation : null,
		tool: followableType === 'Tool' ? relation : null,
		topic: followableType === 'Topic' ? relation : null,
	};
};

export const decorateExistingInterest = ({ followableId, followableType }) => {
	return { followableId, followableType };
};
