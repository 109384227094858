import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { AuthBtn } from 'components/auth/auth-btn';
import { Skeleton } from 'molecules/skeletons';

const ContributeBtn = ({ bounty, loading, onClick }) => {
	// Render
	return (
		<AuthBtn size='lg' onClick={onClick} disabled={loading} className='w-full'>
			{loading ? (
				<Skeleton theme={{ color: '#e5e7eb', highlight: '#d1d5db' }} className='w-20' />
			) : (
				<Span display='flex-middle' space='space-x-4'>
					<FadIcon icon='credit-card' fontSize='text-xl' />
					<Span>Contribute</Span>
				</Span>
			)}
		</AuthBtn>
	);
};

export { ContributeBtn };
