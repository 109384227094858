import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { AccentLink, BorderLink, Link } from '@playbooks/interface/links';
import { useSession } from 'contexts';

const AuthLink = ({ type = '', href, ...props }) => {
	const router = useRouter();
	const session = useSession();

	// Computed
	const computedHref = useMemo(() => {
		return !session.isAuthenticated() ? `/login?redirect=${href}` : href;
	}, [router.pathname, session.isAuthenticated()]);

	// Render
	switch (type) {
		case 'accent':
			return <AccentLink href={computedHref} {...props} />;
		case 'border':
			return <BorderLink href={computedHref} {...props} />;
		default:
			return <Link href={computedHref} {...props} />;
	}
};

export { AuthLink };
